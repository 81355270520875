<script setup lang="ts">
import { useCustomizerStore } from '@/stores/customizer';
import { pl, zhHans } from 'vuetify/locale'
import { IconSettings } from '@tabler/icons-vue'
const customizer = useCustomizerStore();
const title = ref("");
useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk}`
      : "";
  },
});

</script>


<template>
    <!-----RTL LAYOUT------->
    <v-locale-provider> 
        <v-app
            :theme="customizer.actTheme"
            class="padding-top-env"
            :class="[
                customizer.actTheme,
                customizer.mini_sidebar ? 'mini-sidebar' : '',
                customizer.setHorizontalLayout ? 'horizontalLayout' : 'verticalLayout',
                customizer.setBorderCard ? 'cardBordered' : ''
            ]"
        >

             <!---Customizer location right side--->
            <!-- <v-navigation-drawer app temporary elevation="10" location="right" v-model="customizer.Customizer_drawer" width="320">
            <LayoutFullCustomizer/>
            </v-navigation-drawer> -->

            <!-- <LayoutFullVerticalSidebar v-if="!customizer.setHorizontalLayout" /> -->
            <!-- <LayoutFullVerticalHeader v-if="!customizer.setHorizontalLayout" /> -->
            <LayoutFullHorizontalHeader v-if="customizer.setHorizontalLayout" />
            <LayoutFullHorizontalSidebar v-if="customizer.setHorizontalLayout" />
            <v-main>
               <v-container fluid class="page-wrapper pb-sm-15 pb-2">
                    <div :class="customizer.boxed ? 'maxWidth' : ''">
                        <NuxtPage />
                        <!-- <v-btn
                            class="customizer-btn"
                            size="large"
                            icon
                            variant="flat"
                            color="primary"
                            @click.stop="customizer.SET_CUSTOMIZER_DRAWER(!customizer.Customizer_drawer)"
                        >
                            <IconSettings class="text-white" stroke-width="1.5" color="white" size="26" />
                        </v-btn> -->
                    </div>
                </v-container>
            </v-main>
        </v-app>
    </v-locale-provider>
</template>
